<template>
  <div class="pt-20 main-p">
    <div class="asset pt-16">
      <div class="assetTitle text-c text-b size-24">
        {{ $t("我的当前资产") }}
      </div>
      <div class="flex ac jc main">
        <hbxw-number-scrolling
          :value="usdt"
          :decimal="14"
          :isMillennials="true"
          fontSize="0.44rem"
          color="#FFFFFF"
        />
        <div class="size-24 ml-10" style="margin-top: 0.3rem">Token</div>
      </div>
      <div class="flex jc" v-show="address">
        <div class="assetBtn size-22" @click="openPopup">
          <img src="../assets/img3.png" class="assetIcon" />
          {{ $t("捐赠底池") }}
        </div>
      </div>
    </div>

    <div class="assetBox pl-40 pr-40">
      <div class="flex jb ac">
        <div class="size-26 color-a3">{{ $t("团队业绩") }}</div>
        <div class="size-26 texb-b">{{ team_kpi }} Token</div>
      </div>
      <div class="flex jb ac mt-40">
        <div class="size-26 color-a3">{{ $t("推广链接") }}</div>
        <div class="flex ac" ref="copyBtn" v-show="isBindReferral">
          <div class="size-26 texb-b">
            {{ address || '--' }}
          </div>
          <img src="../assets/copy.png" class="img-30 ml-25" />
        </div>
        <div class="flex ac" v-show="!isBindReferral">
          <div class="size-26 texb-b">--</div>
        </div>
      </div>
    </div>

    <div class="box mt-30 pl-40 flex flex-d jc mb-30">
      <div class="flex ac">
        <div class="boxLine mr-15"></div>
        <div class="size-26 text-b">{{ $t("LAF做市商投资规划") }}</div>
      </div>
      <div class="size-20 mt-20">
        {{
          $t(
            "捐赠底池后..."
          )
        }}
      </div>
    </div>
    <div v-loading="listLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
     <home-list :list="orders" @refresh="setListTimer"></home-list> 
    </div>
    

    <popup-box
      :title="$t('增加资产')"
      v-if="show"
      @close="show = false"
      :loading="loading"
      @submit="submit"
    >
      
      <!-- <el-dropdown @command="handleCommand" style="width: 100%;">
        <div class="popupInput size-28 flex jb ac mt-30">
          <div v-if="current>=0">{{ times[current].name }}</div>
          <div style="color:#999999" v-else>请选择天数</div>
          <i class="el-icon-arrow-down"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="index" v-for="(item,index) in times" :key="item.value">{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <input
        type="number"
        v-model="amount"
        :placeholder="$t('请输入增加金额')"
        class="popupInput size-28"
      />
      <div class="flex jb ac mt-20">
        <div class="size-24">{{ $t("最大") }} : {{ max || 0 }}</div>
        <el-dropdown @command="handleCommand" trigger="click">
          <div class="size-24 flex jb ac" style="color: #FFFFFF;">
            <div v-if="current>=0" style="color: #1362FD;">{{ times[current].name }} {{$t('天')}}, {{$t('复利')}} {{times[current].rate}}</div>
            <div style="color:#999999" v-else>{{$t('请选择天数')}}</div>
            <i class="el-icon-arrow-down ml-20"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="index" v-for="(item,index) in times" :key="item.value">{{ item.name }} {{$t('天')}}, {{$t('复利')}} {{item.rate}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      
    </popup-box>

    <popup-box
      :title="$t('确认邀请人')"
      v-if="showRef"
      @close="showRef = false"
      :loading="refLoading"
      @submit="submit1"
    >
      <input
        type="text"
        v-model="refAddress"
        :placeholder="$t('请输入邀请人地址')"
        class="popupInput size-28"
      />
    </popup-box>
  </div>
</template>

<script>
import HomeList from "./HomeList.vue";
import PopupBox from "./PopupBox.vue";
import http from "@/utils/request";
import { mapGetters } from "vuex";
import { bigToSmall, compare, copyLink, initCountdown, smallToBig } from "@/utils";
import BigNumber from "bignumber.js";
import config from "@/config";
const { usdt_address, laf_address, staking_biz_address, approveAmount } = config;
import hbxwNumberScrolling from "./hbxw-number-scrolling/components/hbxw-number-scrolling/hbxw-number-scrolling.vue";
import Clipboard from "clipboard";
export default {
  components: { HomeList, PopupBox, hbxwNumberScrolling },
  name: "HomePage",
  comments: {
    HomeList,
  },
  data() {
    return {
      show: false,
      loading: false,
      showRef: false,
      refLoading: false,
      amount: "",
      refAddress: "",
      isBindReferral: false,
      allowance: 0,
      balance: 0,

      getMaxTimer: null,
      getUsdtTimer: null,
      getListTimer: null,
      countDownTimer: null,
      max: 0,
      usdt: 0,
      team_kpi: 0,
      orders: [],

      current:-1,
      times:[
        {name:'1',value:'0',rate:'0.3%'},
        {name:'15',value:'1',rate:'0.6%'},
        {name:'30',value:'2',rate:'1.2%'},
      ],

      clip:null,

      listLoading:false,

      levelName:''
    };
  },
  computed: {
    ...mapGetters([
      "address",
      "erc20",
      "staking",
      "referral",
      "uniswapV2Router",
    ]),
    inviteUrl() {
      return this.$config.inviteUrl + this.$store.state.dapp.address;
    },
  },
  watch: {
    address: {
      async handler(newVal, oldVal) {
        if (newVal) {
          // 如果地址存在且变动，打开或更新定时任务
          this.setMaxTimer();
          this.setUsdtTimer();
          this.setListTimer();
          this.initReferral();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    this.clip = new Clipboard(this.$refs.copyBtn, {
      text: () => this.inviteUrl,
    });
    this.clip.on("success", (e) => {
      this.showToast(this.$t("已复制"), "success");
    });

  },
  beforeDestroy() {
    this.clip.destroy();
  },

  methods: {
    handleCommand(command){
      this.current = command
    },
    // 重置最大值定时器
    async setMaxTimer() {
      clearInterval(this.getMaxTimer);
      this.getMaxTimer = null;
      if (this.staking) {
        this.max = await this.getMax();
        setInterval(async () => (this.max = await this.getMax()), 30000);
      }
    },
    // 重置资产定时器
    async setUsdtTimer() {
      clearInterval(this.getUsdtTimer);
      this.getUsdtTimer = null;
      if (this.staking) {
        this.usdt = await this.getUsdt();
        setInterval(async () => (this.usdt = await this.getUsdt()), 5000);
      }
    },
    // 重置列表和业绩定时器
    async setListTimer() {
      clearInterval(this.getListTimer);
      this.getListTimer = null;
      if (this.staking) {
        const { team_kpi, orders } = await this.getList();
        this.team_kpi = team_kpi;
        this.orders = orders;
        this.setCountDownTimer()
        setInterval(async () => {
          const { team_kpi, orders } = await this.getList();
          this.team_kpi = team_kpi;
          this.orders = orders;
          this.setCountDownTimer()
        }, 10000);
      }
    },
    setCountDownTimer(){
      clearInterval(this.countDownTimer)
      this.countDownTimer = null
      for(let i=0;i<this.orders.length;i++){
        let obj = this.orders[i]
        if(obj.status==1){
          if(obj.djs>0){
            obj['countdownTxt'] = initCountdown(obj.djs)
            obj.djs -= 1
            this.$set(this.orders, i, obj)
          }else{
            obj['countdownTxt'] = '00:00'
            clearInterval(this.countDownTimer)
            this.countDownTimer = null
            this.setListTimer();
            break;
          }
        }
      }
      if(this.orders.length>0){
        this.countDownTimer = setInterval(() => {
          for(let i=0;i<this.orders.length;i++){
            let obj = this.orders[i]
            if(obj.status==1){
              if(obj.djs>0){
                obj['countdownTxt'] = initCountdown(obj.djs)
                obj.djs -= 1
                this.$set(this.orders, i, obj)
              }else{
                obj['countdownTxt'] = '00:00'
                clearInterval(this.countDownTimer)
                this.countDownTimer = null
                this.setListTimer();
                break;
              }
            }
          }
        }, 1000);
      }
    },
    // 获取最大值
    async getMax() {
      const max = await this.staking.maxStakeAmount();
      const data = bigToSmall(max);
      console.log('获取最大值：'+data);
      return data;
    },
    // 获取资产
    async getUsdt() {
      const balance = await this.staking.balanceOf();
      const data = new BigNumber(balance).div("1e18").toNumber();
      console.log('获取资产：'+data);
      return data;
    },
    // 获取列表和业绩
    async getList() {
      const data = await http("/api/index", {
        address: this.$store.state.dapp.address,
      });
      const { team_kpi, orders } = data
      orders.forEach(async e=>{
        if(e.status==1){
          let num = await this.staking.rewardOfSlot(e.index)
          e.reward = new BigNumber(num).div("1e18").dp(4).toNumber();
        }
      })
      console.log('获取列表');
      return data;
    },
    openPopup() {
      this.amount = "";
      this.show = true;
    },
    async getOutMin() {
      const num = smallToBig(this.amount / 2);
      const outMins = await this.uniswapV2Router.getAmountsOut(num, [
        usdt_address,
        laf_address,
      ]);
      const outMin = new BigNumber(outMins[1].toString()).times(0.9).toFixed(0);
      return outMin;
    },
    async makeAuth() {
      this.erc20
        .approve(staking_biz_address, approveAmount)
        .then(async (result) => {
          await result.wait();
          this.showToast(this.$t("授权成功"), "success");
          this.submit();
        })
        .catch((err) => {
          console.log(err);
          this.showToast(this.$t("授权失败"), "error");
          this.loading = false
          this.show = false;
        });
    },
    async submit() {
      if (!this.amount) return this.showToast(this.$t("请输入增加金额"));
      if (Number(this.amount) > Number(this.max))
        return this.showToast(this.$t("不能超过最大值"));
      if (this.current<0 || this.times.length==0) return this.showToast(this.$t("请选择天数"));

      const allowance = await this.erc20.allowance(null, staking_biz_address);
      const balance = await this.erc20.balanceOf();

      const amount = smallToBig(this.amount);
      if (compare(amount, balance) > 0) return this.showToast("余额不足");
      this.loading = true
      if (compare(amount, allowance) > 0) return this.makeAuth();
      const outMin = await this.getOutMin();
      if (this.isBindReferral) {
        // 如果绑定过上级了
        this.staking
          .stake(amount, outMin, this.times[this.current].value)
          .then(async (result) => {
            await result.wait();
            this.showToast(this.$t("操作成功"), "success");
            this.loading = false
            this.show = false;
          })
          .catch(() => {
            this.showToast(this.$t("操作失败"), "error");
            this.loading = false
            this.show = false;
          });
      } else {
        // 没绑定则打开绑定确认弹窗
        this.showRef = true;
        this.loading = false
        this.show = false;
      }
    },
    // 确认绑定人后的充值
    async submit1() {
      this.refLoading = true
      const outMin = await this.getOutMin();
      const amount = smallToBig(this.amount);
      this.staking.stakeWithInviter(amount, outMin,this.times[this.current].value, this.refAddress).then(async (result)=>{
        await result.wait();
        this.showToast(this.$t("操作成功"), "success");
        this.refLoading = false
        this.showRef = false;
      })
      .catch(() => {
        this.showToast(this.$t("操作失败"), "error");
        this.refLoading = false
        this.showRef = false;
      });
    },
    copyData() {
      if (this.address && this.isBindReferral) {
        copyLink(this.inviteUrl);
      }
    },
    async initReferral() {
      // 是否绑定上级
      this.isBindReferral = await this.referral.isBindReferral();
      if (!this.isBindReferral) {
        // 绑定上级以地址栏携带的地址优先
        const ref = this.$store.state.user.ref;
        if (ref) {
          this.refAddress = ref;
        } else {
          // 否则从合约取默认绑定地址
          const refAddress = await this.referral.getRootAddress();
          this.refAddress = refAddress;
          this.$store.commit("user/SET_REF", refAddress);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.copyBtn {
  display: none;
}
.asset {
  width: 100%;
  height: 2.62rem;
  background-image: url("../assets/img2.png");
  background-size: 100% 100%;
  position: relative;
  z-index: 1;
  .assetTitle {
    color: #19ecff;
  }
  .assetMonty {
    &::after {
      content: "USDT";
      font-size: 0.24rem;
      margin-left: 0.2rem;
    }
  }
  .assetBtn {
    height: 0.48rem;
    line-height: 0.48rem;
    border-radius: 0.24rem;
    padding: 0 0.24rem 0 0.68rem;
    background-color: #1362fd;
    position: relative;
    .assetIcon {
      width: 0.56rem;
      height: 0.56rem;
      position: absolute;
      left: 0;
      top: -0.04rem;
      z-index: 1;
    }
  }
}

.assetBox {
  border-radius: 0 0 0.4rem 0.4rem;
  background: rgba(19, 98, 253, 0.1);
  margin-top: -0.3rem;
  padding-top: 0.7rem;
  padding-bottom: 0.4rem;
  box-shadow: inset 0 0 0.3rem #1362fd;
}

.box {
  padding: .2rem 0;
  background-image: url("../assets/bg1.png");
  background-size: 100% 100%;
  padding-right: 2rem;
  .boxLine {
    width: 0.06rem;
    height: 0.22rem;
    border-radius: 0.03rem;
    background-color: #ffffff;
  }
}

.popupInput {
  width: 100%;
  height: 0.86rem;
  border-radius: 0.2rem;
  background-color: #172745;
  padding: 0 0.3rem;
  color: #ffffff;
  &::placeholder {
    color: #999999;
    font-size: 0.28rem;
  }
  &:focus {
    border: none;
  }
}
</style>